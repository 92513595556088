<template>
  <div class="card info-card float-right">
    <div class="card-body ">
<p>Neu in der Welt von Cinzell?</p>
   <p>
     <i class="bi bi-book" id="card-icon"></i>
   </p>
    <p>
      <a href="#textblock1" class="btn btn-primary btn-info-cinzell">Mehr Infos!</a>
    </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoCard"
}
</script>

<style scoped>
@media (min-width: 0px) {
  .info-card {
    display:none;
  }
}
@media (min-width: 576px) {
  .info-card {
    display:none;
  }
}
@media (min-width: 768px) {
  .info-card {
    display:flex;
  }
}

.info-card {
  z-index:9999;
  position:absolute;
  margin:1.75em 0 0 5em;
  border-radius: 0;
  color:var(--cinzell-regular);
  border:2px solid var(--cinzell-regular);
  width:10em;
  background:#000;
  font-size:1.2em;
  font-family:"Georgia","Times New Roman", serif;
  font-variant:all-small-caps;
  min-height:170px;
}
#card-icon {
  font-size:3em;
  color:var(--cinzell-regular);
  transition:1s color;
}
#card-icon:hover {
  color:var(--cinzell-regular-light);
}

.btn-info-cinzell:hover, .btn-info-cinzell:active {
  color:#000;
  font-weight:bold;
  border: 1px solid rgb(208,172,105);


}
</style>
