<template>
  <div class="container" id="textblock1">
    <div class="textblock-header">
    <h1>Cinzell - Geschichten aus den Flusslanden</h1>
    </div>
 <div class="textblock">


   <p>
     Cinzell – Geschichten aus den Flusslanden entführt dich in eine faszinierende, mittelalterlich geprägte Welt voller Abenteuer und Magie. Diese epische Romanreihe ist ideal für Kinder, Jugendliche und Erwachsene, die sich von Fantasy, High-Fantasy und Animal-Fantasy begeistern lassen. In den Flusslanden tummeln sich Tiere, die in charmanten Städten und Dörfern leben, verteilt über eine abwechslungsreiche und farbenfrohe Welt.
   </p>
   <p>
     Begleite mutige Helden, einfache Krämer, Schmiede oder pelzige Abenteurer die ihrem Tagwerk nachgehen, und entdecke die einzigartigen Gemeinschaften, die sie bilden. Doch nicht alles ist friedlich: Ständig brodeln Spannungen zwischen den verschiedenen Regionen – sei es wegen Streitigkeiten um wertvolle Ressourcen oder Grenzkonflikte. Und als ob das nicht genug wäre, droht eine düstere Gefahr von außen: die unheilvollen Bewohner der „Ödlande“.
   </p>
   <p>
     Diese feindselige Nation, bevölkert von furchteinflößenden Kreaturen wie Ratten, Krähen und Mardern, stellt die Tiergemeinschaften der Flusslande vor gewaltige Herausforderungen. Mach dich bereit für eine Geschichte voller Intrigen, Mut und Zusammenhalt, in der die Grenzen zwischen Gut und Böse oft fließend sind. Erlebe, wie unsere tierischen Helden die Flusslande gegen die dunklen Mächte verteidigen – und entdecke dabei eine Welt, die dich nicht mehr loslassen wird.
   </p>
 </div>
  </div>
</template>

<script>
export default {
  name: "TextBlock"
}
</script>

<style scoped>
.textblock-header {
  margin:2em 0 2em 0;
  font-family:"Georgia", serif;
}
.textblock {
  min-height:300px;
  font-family:"Georgia", serif;
  text-align:left;
  margin:2em 0 2em 0;
}
@media (min-width: 0px) {
  .textblock {
    column-count: 1;
  }
}
@media (min-width: 576px) {
  .textblock {
    column-count: 1;
  }
}
@media (min-width: 768px) {
  .textblock {
    column-count: 2;
  }
}
</style>
