<template>

    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text">
        {{ text }}
      </p>

    </div>

</template>

<script>

export default {

  name: "NewsBarCard",
  props:
    {
    title: String,
    text: String,
    link: String,
    linkText: {
      type: String,
      required: true
    },
      path: undefined
  }
}

</script>

<style scoped>
</style>
