<template>
<div class="header-image">
  <img id="image" src='../assets/img/header-image.png'>
</div>
</template>

<script>
export default {
  name: "HeaderImage"
}
</script>

<style scoped>
.header-image {
  width:100%;
  max-height:350px;
  overflow: hidden;
}
#image {
  background-image:url('../assets/img/header-image.png');
  width:100%;

}
</style>
