<template>
  <nav class="navbar navbar-expand-lg navbar-cinzell bg-cinzell">
    <a class="navbar-brand" href="#">
      <img :src="require('../assets/img/cinzell_logo.png')" id="logo" alt="cinzell-logo"/>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" :class="!visible?'collapse':''"  id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#news1">Neuigkeiten</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#textblock1">Über Cinzell</a>
        </li>

<li class="nav-item">
  <a class="nav-link" href="https://www.instagram.com/cinzell_buch/" target="_blank"><i class="bi bi-instagram"></i></a>
</li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" target="_blank" disabled> <i class="bi bi-amazon"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link " href="mailto:post@cinzell.de" target="_blank" >     <i class="bi bi-envelope-fill"></i></a>
        </li>

      </ul>

    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>
.navbar-toggler {
  margin-right:1em;
}
#logo {
  padding:0 0 0 1em;
  max-width:150px;
}
disabled {
  color:grey;
}
.navbar-toggler {
  border:1px solid rgb(208,172,105);
}

.custom-toggler, .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(208,172,105, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler, .navbar-toggler {
  border-color: rgba(208,172,105, 1);
}
.custom-toggler:active, .navbar-toggler:active {
  border-color: rgba(208,172,105, 1);
}
.navbar {
  border-bottom:1px solid rgb(208, 172, 105);

}
</style>
