<template>
  <div class="newsbar" >
    <div class="container" id="#news1">
      <h1>Neuigkeiten</h1>
      <div class="newsbarcards">
        <div class="card newsbarcard" >
          <img class="card-img-top" :src="img1"/>
          <news-bar-card title="Cinzell auf Instagram!"
                         text="Cinzells Instagram-Profil ist online. Bilder, News und Updates zum Buch. Folge uns doch direkt unter:" />
          <div class="card-footer text-muted">
            <a href="https://www.instagram.com/cinzell_buch/" target="_blank" class="btn btn-primary btn-secondary-cinzell">Zur Seite</a>
          </div>
        </div>
        <div class="card newsbarcard">
          <img class="card-img-top" :src="img2"/>
          <news-bar-card title="Das Postfach ist bereit"
                         text="Fragen oder Anregungen? Schreib' uns einfach unter:" />
            <div class="card-footer text-muted">
              <a href="mailto:post@cinzell.de" class="btn btn-primary btn-secondary-cinzell" style="font-size:0.9em">post@cinzell.de</a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Image1 from "../assets/img/cinzell_instagram.png";
import Image2 from "../assets/img/cinzell_mail.png";

import NewsBarCard from "@/components/NewsBarCard";

export default {
  name: "NewsBar",
  components: {NewsBarCard},
  props: ["path"],

  data: function () {
    return {
      img1: Image1,
      img2:Image2
    };
  }
}
</script>

<style scoped>
.newsbarcards {
  display:inline-flex;
}
.newsbar {
  border-top: 1px solid rgb(208, 172, 105);
  border-bottom: 1px solid rgb(208, 172, 105);
  padding: 1.75em 0 0 0;
  min-height: 250px;
  background: rgb(25, 54, 32);
  background: linear-gradient(180deg, rgba(25, 54, 32, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

a {
  text-decoration: none;
}
.card-footer {
  border-top:none;
}
.newsbar h1 {
  font-family: "Georgia", serif;
}

.newsbarcards {
  margin: 2em 0 2em 0;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  flex-direction: row;
}

.newsbarcard {
  border: 1px solid rgb(208, 172, 105);
  background: rgb(208,172,105);
  background: linear-gradient(90deg, rgba(208,172,105,1) 0%, rgba(248,230,192,1) 50%, rgba(208,172,105,1) 100%);
  color: #000;


}
@media (min-width: 0px) {
  .newsbarcard {
    width:100%;
    max-width:17em;
    margin:0 1em 0 1em;
  }
}
@media (min-width: 576px) {
  .newsbarcard {
    width:50%;
    max-width:18em;

  }
}
@media (min-width: 768px) {
  .newsbarcard {
    width:50%;
    max-width:18em;

  }
}
</style>
